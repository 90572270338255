import { formFieldTypes } from "../../common/constants/formFieldTypes";
import {
    isDateFormFieldType,
    isMultiValueFormFieldType,
    isNumericFormFieldType,
    isSwitchFormFieldType,
} from "../../utils/forms";

/**
 * Format custom data params
 * @param formParams
 * @param lookupParams
 * @param paramName
 * @param formattedFormParams
 */
export const getCustomDataParam = (formParams, lookupParams, paramName, formattedFormParams) => {
    const paramNameRegex = paramName.replace(new RegExp("_[0-9]+$", "ig"), "");

    if (lookupParams[paramName]) {
        if (lookupParams[paramName].key === "EMPTY") {
            if (typeof formattedFormParams[paramNameRegex] !== "undefined") {
                formattedFormParams[paramNameRegex] = formattedFormParams[paramNameRegex]
                    .toString()
                    .concat("--")
                    .concat(lookupParams[paramName].key);
            } else {
                formattedFormParams[paramNameRegex] = lookupParams[paramName].key;
            }
        } else {
            formattedFormParams[`${paramNameRegex}-${lookupParams[paramName].key}`] =
                lookupParams[paramName].value;
        }
    } else if (formParams[paramName].key === "EMPTY") {
        if (typeof formattedFormParams[paramNameRegex] !== "undefined") {
            formattedFormParams[paramNameRegex] = formattedFormParams[paramNameRegex]
                .toString()
                .concat("--")
                .concat(lookupParams[paramName].key);
        } else {
            formattedFormParams[paramNameRegex] = lookupParams[paramName].key;
        }
    } else if (formParams[paramName].value) {
        if (formattedFormParams[`${paramNameRegex}-${formParams[paramName].key}`]) {
            formattedFormParams[
                `${paramNameRegex}-${formParams[paramName].key}`
            ] += `--${formParams[paramName].value}`;
        } else {
            formattedFormParams[`${paramNameRegex}-${formParams[paramName].key}`] =
                formParams[paramName].value;
        }
    } else if (formattedFormParams[paramNameRegex]) {
        // Key only
        formattedFormParams[paramNameRegex].push(formParams[paramName].key);
    } else {
        formattedFormParams[paramNameRegex] = [formParams[paramName].key];
    }
};

/**
 * Format the numeric exclude params
 * @param matches
 * @param excludeParams
 * @returns {*}
 */
export const getNumericExcludeParam = (matches, excludeParams) => {
    let paramsToExclude = excludeParams;
    matches.forEach((match, index) => {
        const val = match.match(new RegExp("-?[0-9.,]+", "g")) || match.match(new RegExp("EMPTY"));
        if (val === null || typeof val === "undefined") {
            return;
        }

        if (index === 0) {
            paramsToExclude = `!=${val}`;
        } else {
            paramsToExclude += `--!=${val}`;
        }
    });

    return paramsToExclude;
};

/**
 * Merge params to request
 * @param formParams
 * @param lookupParams
 * @param excludeParams
 * @param columnParams
 * @param attributes
 * @returns {{}}
 */
export const buildSearchParams = (
    formParams,
    lookupParams,
    excludeParams,
    columnParams,
    attributes
) => {
    let formattedFormParams = {};

    let _formParams = { ...formParams };
    const fpKeys = Object.keys(_formParams);

    let _columnParams = { ...columnParams };
    Object.keys(columnParams).forEach((clmParamKey) => {
        if (clmParamKey === "auth_method" && _columnParams[clmParamKey].length > 0) {
            _columnParams[clmParamKey] = _columnParams[clmParamKey].map((item) => {
                if (item !== "EMPTY") {
                    if (item === "APPLE-PAY") {
                        return "ALL-APPLE-PAY";
                    }
                    return "ALL-3DS";
                }
                return "EMPTY";
            });
        }
    });

    // If lookupParams contains one key attribute => remove attribute from formParams
    // Only for multiple attributes
    Object.keys(lookupParams).forEach((lkpParamKey) => {
        const attribute = attributes[lkpParamKey];
        if (attribute && attribute.multiple === true) {
            const filteredKeys = fpKeys.filter((key) => key.startsWith(`${lkpParamKey}_`));
            filteredKeys.forEach((key) => {
                if (
                    !!_formParams[key] &&
                    key.replace(new RegExp("_[0-9]+", "ig"), "") === attribute.id
                ) {
                    delete _formParams[key];
                }
            });
        }
    });

    Object.keys(formParams).forEach((frmParamKey) => {
        if (frmParamKey === "auth_method" && _formParams[frmParamKey].length > 0) {
            _formParams[frmParamKey] = _formParams[frmParamKey].map((item) => {
                if (item !== "EMPTY") {
                    if (item === "APPLE-PAY") {
                        return "ALL-APPLE-PAY";
                    }
                    return "ALL-3DS";
                }
                return "EMPTY";
            });
        }
    });

    let params = {
        ..._formParams,
        ..._columnParams,
        ...lookupParams,
    };

    if (Object.keys(params)) {
        Object.keys(params).forEach((paramName) => {
            if (
                params[paramName] &&
                attributes[paramName.replace(new RegExp("_[0-9]+", "ig"), "")] &&
                attributes[paramName.replace(new RegExp("_[0-9]+", "ig"), "")].formFieldType ===
                    formFieldTypes.FORM_FIELD_CUSTOM_DATA
            ) {
                getCustomDataParam(formParams, lookupParams, paramName, formattedFormParams);
            } else if (lookupParams[paramName]) {
                formattedFormParams[paramName] =
                    !attributes[paramName] ||
                    isNumericFormFieldType(attributes[paramName]) ||
                    isDateFormFieldType(attributes[paramName]) ||
                    isSwitchFormFieldType(attributes[paramName]) ||
                    isMultiValueFormFieldType(attributes[paramName].formFieldType)
                        ? lookupParams[paramName]
                        : lookupParams[paramName] === "EMPTY"
                        ? `${lookupParams[paramName]}`
                        : `"${lookupParams[paramName]}"`;
            } else {
                formattedFormParams[paramName] = params[paramName];
            }
        });
    }

    // Complete previous params
    if (excludeParams) {
        Object.keys(excludeParams).forEach((paramName) => {
            const dryKey = paramName.replace(new RegExp("_[0-9]+", "ig"), "");
            const attribute = attributes[dryKey];
            if (attribute) {
                if (isMultiValueFormFieldType(attribute.formFieldType)) {
                    if (excludeParams[paramName].key) {
                        if (excludeParams[paramName].key === "EMPTY") {
                            if (typeof formattedFormParams[dryKey] !== "undefined") {
                                formattedFormParams[dryKey] = formattedFormParams[dryKey]
                                    .toString()
                                    .concat("--")
                                    .concat(`NOT ${excludeParams[paramName].key}`);
                            } else {
                                formattedFormParams[dryKey] = `NOT ${excludeParams[paramName].key}`;
                            }
                        } else if (
                            !formattedFormParams[`${dryKey}-NOT_${excludeParams[paramName].key}`]
                        ) {
                            formattedFormParams[`${dryKey}-NOT_${excludeParams[paramName].key}`] =
                                excludeParams[paramName].value;
                        } else {
                            formattedFormParams[
                                `${dryKey}-NOT_${excludeParams[paramName].key}`
                            ] += `--${excludeParams[paramName].value}`;
                        }
                    } else {
                        let excludeValue = excludeParams[paramName].map((item) => "NOT " + item);
                        if (!formattedFormParams[paramName]) {
                            formattedFormParams[paramName] = excludeValue;
                        } else {
                            formattedFormParams[paramName] =
                                formattedFormParams[paramName].concat(excludeValue);
                        }
                    }
                } else {
                    let paramToExclude = excludeParams[paramName];
                    if (
                        attribute.formFieldType &&
                        attribute.formFieldType === formFieldTypes.FORM_FIELD_NUMERIC
                    ) {
                        let matches = excludeParams[paramName].match(
                            new RegExp('((?:NOT) \\("([^"]+)")\\)|(NOT \\(EMPTY\\))', "g")
                        );
                        paramToExclude = matches
                            ? getNumericExcludeParam(matches, excludeParams[paramName])
                            : excludeParams[paramName];
                    }

                    if (typeof formattedFormParams[paramName] !== "undefined") {
                        formattedFormParams[paramName] = formattedFormParams[paramName]
                            .toString()
                            .concat("--")
                            .concat(paramToExclude);
                    } else {
                        formattedFormParams[paramName] = paramToExclude;
                    }
                }
            }
        });
    }

    return { ...formattedFormParams };
};
