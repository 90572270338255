import configReference from "./references";
import { getReferenceAsOptions } from "@console/core";
import { get } from "react-hook-form";

/**
 * @param name
 * @param itemId
 * @param storeRef
 * @param p (optional, for translating label)
 * @returns object|null
 */
export const getReferenceItem = (name, itemId, storeRef, p = false) => {
    const referenceData = getReferenceAsOptions(storeRef, name, p, {}, configReference);
    const item = referenceData.find((item) => item.id === itemId);
    return item ? item : null;
};

/**
 * @param name
 * @param itemId
 * @param storeRef
 * @param p (optional, for translating label)
 * @returns object|null
 */
export const getReferenceItemLabel = (name, itemId, storeRef, p = false) => {
    const item = getReferenceItem(name, itemId, storeRef, p);
    return item ? item.label : itemId;
};

/**
 * @param params Object
 * @returns {string}
 */
export const getParamsString = (params) => {
    return Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");
};

/**
 * @param accountName String
 * @returns {string}
 */
export const generateAccountNameInitials = (accountName) => {
    const parts = accountName.toUpperCase().split(" "); // Split at spaces
    return parts.length > 1
        ? parts[0].substring(0, 1) + parts[1].substring(0, 1)
        : parts[0].substring(0, 2);
};

/**
 * @param apiField
 * @returns {null}
 */
export const getApiField = (apiField) => {
    const fieldParts = apiField.split(".");
    return fieldParts[0];
};

/**
 * @param apiField
 * @returns {null}
 */
export const getApiSubField = (apiField) => {
    const fieldParts = apiField.split(".");
    return fieldParts.length > 1 ? fieldParts[1] : null;
};

/**
 * @param apiField
 * @returns {null}
 */
export const getApiSubSubField = (apiField) => {
    const fieldParts = apiField.split(".");
    return fieldParts.length > 2 ? fieldParts[2] : null;
};

/**
 * Convert ""val"" to val or "val" to val
 * @param value
 * @returns {*}
 */
export const removeWrappingQuotes = (value) => {
    return value.replace(/^("?")|("?")$/g, "");
};

/**
 * Return if internal env
 * @returns {boolean}
 */
export const isInternal = "true" === process.env.NX_IS_INTERNAL;

/**
 * Format IBAN value (XXXX XXXX XXXX)
 * @param val
 * @returns {string}
 */
export const formatIban = (val) => {
    return val
        ? val
              .match(/.{1,4}/g)
              .join(" ")
              .toUpperCase()
        : "";
};

/**
 * Unformat IBAN value
 * @param val
 * @returns {string}
 */
export const unFormatIban = (val) => {
    return val ? val.replace(/ /g, "") : "";
};

export const errorExist = (form, name) => {
    const error = get(form.errors, name);
    return typeof error !== "undefined";
};

export const errorText = (form, name) => {
    const error = get(form.errors, name);
    if (typeof error !== "object") {
        return null;
    }
    if (error.message) {
        return error.message;
    }
    switch (error.type) {
        case "required":
            return "Ce champ est requis";
        default:
            return null;
    }
};

export const handleApiViolations = (violations, form) => {
    if (Array.isArray(violations)) {
        violations.forEach((violation) => {
            const field = form.getValues(violation.propertyPath);
            if (typeof field !== "undefined") {
                form.setError(violation.propertyPath, {
                    type: "api",
                    message: violation.message,
                    shouldFocus: true,
                });
            }
        });
    }
};

/**
 * Extract first violation message from API Platform error response
 * @param violations
 * @returns {null}
 */
export const getFirstApiViolation = (violations) => {
    if (Array.isArray(violations) && violations.length > 0) {
        return violations[0].message;
    }
    return null;
};

/**
 * Helper to extract value from object
 * @param object
 * @param propertyPath
 * @param fallback
 * @param allowNull
 * @returns {*}
 */
export const valueOrFallback = (object, propertyPath, fallback, allowNull = false) => {
    const propertyPathParts = propertyPath.split(".");
    let currentValue = object;
    for (let i = 0; i < propertyPathParts.length; i++) {
        if (
            currentValue === null ||
            typeof currentValue !== "object" ||
            !Object.prototype.hasOwnProperty.call(currentValue, [propertyPathParts[i]])
        ) {
            return fallback;
        }
        currentValue = currentValue[propertyPathParts[i]];
    }
    if (allowNull === false && currentValue === null) {
        return fallback;
    }
    return currentValue;
};

/**
 * Helper to return string value or fallback if empty
 * @param value
 * @param fallbackValue
 * @returns {*}
 */
export const stringValueOrFallback = (value, fallbackValue) => {
    if (typeof value !== "string") {
        throw new Error("Expected a string");
    }
    return value !== "" ? value : fallbackValue;
};

/**
 * Return a human readable duration
 * https://missing-manual.com/duration-parser/
 * @param duration
 * @param p
 */
export const formatIso8601Duration = (duration, p) => {
    const durationRegex =
        /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
    const matches = duration.match(durationRegex) || [];

    let parts = [];
    const timePeriod = [null, "year", "month", "week", "day", "hour", "minute", "second"];
    for (let i = 1; i < 8; i++) {
        if (matches[i] !== undefined)
            parts.push(
                p.t("modules.account.period." + timePeriod[i], {
                    smart_count: parseInt(matches[i]),
                })
            );
    }
    return parts.join(" ");
};

export const percentageToNumber = (percentage) => {
    return percentage > 100 ? 1 : percentage < 0 ? 0 : percentage / 100;
};

/**
 * Return true if user is a merchant
 * @param user The user object from useUser()
 * @returns {boolean}
 */
export const isMerchant = (user) => {
    if (!user || !user.roles || !Array.isArray(user.roles)) {
        return false;
    }
    return user.roles.includes("ROLE_MERCHANT") || user.roles.includes("ROLE_MERCHANT_ADMIN");
};

/**
 * Extract searchReferer value from useHistory
 * @param history
 * @returns {string|null}
 */
export const extractSearchReferer = (history) => {
    return history.location.state && history.location.state.searchReferer
        ? history.location.state.searchReferer
        : null;
};

/**
 * Stop an event propagation
 * @param e
 */
export const stopPropagationEvent = (e) => {
    return e.stopPropagation();
};
